import React from 'react'
import { useAuth } from '../../contexts/AuthContext';
import '../../style.css';
import { useNavigate } from "react-router-dom";

import { Paper, Typography, Alert, Box, IconButton, Tooltip, TextField, Button } from '@mui/material';


import Header from '../../Header'

export default function ForgotPassword() {
    const { resetPassword } = useAuth()
    const [error, setError]  = React.useState('')
    let navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();
        const email = e.target.elements.email.value;
        resetPassword(email).then(function() {
            alert("Password reset email sent");
            navigate('/login');
        }).catch(function(error) {
            alert(error.message);
            setError(error.message)
        });
    }
  return (
      <div>
        {/* <Header showHome={true} showIcons={false} />
        <h2>
            Forgot Password
        </h2>
        <form onSubmit={handleSubmit}>
            <label>
                Email:
                <br/>
                <input type="text" name="email" />
            </label>
            <br/>
            <input type="submit" value="Submit" />
        </form>
        <a href="/login">Back to login</a> */}
        <Header showHome={true} showIcons={false} />
        <Box 
        display="flex" 
        // bgcolor="lightgreen"
        alignItems="center"
        justifyContent="center"
        sx={{
            minHeight: "80vh",
        }}
      >   
        <Paper className="match-card" elevation={3} sx={{ maxWidth: "500px", width: "100%" }} >
          <Box sx={{ px: 3, pb: 1, pt: 3 }}>
              <Typography variant="h5" component="h3">
                  <b>Forgot Password</b>
              </Typography>
          </Box>
          <Box 
              display="flex" 
              // bgcolor="lightgreen"
              alignItems="center"
              justifyContent="center"
              sx={{
                  pb: 3,
                  pt: 1,
                  px: 2,
              }}
          >   
              <form onSubmit={handleSubmit}>
                  <TextField
                      id="email"
                      label="Email"
                      type="email"
                      name="email"
                      autoComplete="email"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                  />

                  {error && <Alert severity="error">{error}</Alert>}
            
                  <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      sx={{
                        mt: 3
                      }}
                  >
                      Send Recovery Email
                  </Button>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  <Button color="secondary" href="/login" fullWidth>
                      Back to login
                  </Button>
              </form>

            </Box>
            
            </Paper>
        </Box>
      </div>
  )
}
