import React, { useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from "react-router-dom";

import { Paper, Typography, Alert, Box, IconButton, Tooltip, TextField, Button } from '@mui/material';

import { analytics } from '../../config';
import { logEvent } from 'firebase/analytics';

import Header from '../../Header';

export default function Login() {
  let navigate = useNavigate();
  const { login, currentUser } = useAuth();
  const [error, setError] = React.useState(null);
  

  useEffect(() => {
    if (currentUser) {
      navigate('/match');
    }
  }, [])
  

  async function handleLogin(e) {
    e.preventDefault();
    setError(null);
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    try {
      await login(email, password);
      logEvent(analytics, 'login', {
        email: email,
      });
      navigate('/match');
    } catch (error) {
      console.log(error.code);
      setError("Password or email is incorrect");
    }
  }
  return (
    <div>
      <Header showIcons={false} />
      <Box 
        display="flex" 
        // bgcolor="lightgreen"
        alignItems="center"
        justifyContent="center"
        sx={{
            minHeight: "80vh",
        }}
      >   
        <Paper className="match-card" elevation={3} sx={{ maxWidth: "500px", width: "100%" }} >
          <Box sx={{ px: 3, pb: 1, pt: 3 }}>
              <Typography variant="h5" component="h3">
                  <b>Login</b>
              </Typography>
          </Box>
          <Box 
              display="flex" 
              // bgcolor="lightgreen"
              alignItems="center"
              justifyContent="center"
              sx={{
                  pb: 3,
                  pt: 1,
                  px: 2,
              }}
          >   
              <form onSubmit={handleLogin}>
                  <TextField
                      id="email"
                      label="Email"
                      type="email"
                      name="email"
                      autoComplete="email"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                  />
                  <TextField
                      id="password"
                      label="Password"
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                  />

                  {error && <Alert severity="error">{error}</Alert>}
            
                  <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      sx={{
                        mt: 3
                      }}
                  >
                      Login
                  </Button>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  <Button color="secondary" href="/forgotpassword" fullWidth>
                      Forgot Password?
                  </Button>
                  <br/>
                  
                  <Button color="secondary" href="/create" fullWidth>
                      Don't have an account? &nbsp;<b>Sign Up here!</b>
                  </Button>
              </form>

          </Box>
            
        </Paper>
      </Box>
    </div>
  )
}
