import React from "react";
 
// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";
 
// We import all the components we need in our app
import Login from "./pages/login/Login";
import HomePage from "./pages/home/HomePage";
import Create from "./pages/create/Create";
import Info from "./pages/info/InfoPage";
import Match from "./pages/match/Match";
import ForgotPassword from "./pages/login/ForgotPassword";
import Chat_page from "./pages/chat/chat_page";
import Chat_users from "./pages/chat/chat_users";
import Settings from "./pages/settings/settings";

import { AuthProvider } from './contexts/AuthContext'
import PrivateRoute from "./PrivateRoute";
import Logout from "./pages/login/Logout";
 
const App = () => {
 return (
   <div>
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/create" element={<Create />} />
          <Route exact path="/info" element={<Info />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/chat_page" element={<PrivateRoute />}>
            <Route path="/chat_page/:id" element={<Chat_page/>} />
          </Route>
          <Route exact path="/chat_users" element={<PrivateRoute />}>
            <Route exact path="/chat_users/" element={<Chat_users/>} />
          </Route>
          <Route exact path="/match" element={<PrivateRoute />}>
            <Route exact path="/match/" element={<Match />} />
          </Route>
          <Route exact path="/settings" element={<PrivateRoute />}>
            <Route exact path="/settings/" element={<Settings />} />
          </Route>
          <Route exact path="/logout" element={<PrivateRoute />}>
            <Route exact path="/logout/" element={<Logout />} />
          </Route>
        </Routes>
      </AuthProvider>
   </div>
 );
};
 
export default App;
