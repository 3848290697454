import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext';
import firebase from 'firebase/compat/app';

import {firestore} from '../../config';
import 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore'
import 'firebase/analytics'
import { Paper, Typography, Box, IconButton, Tooltip, Button, Badge } from '@mui/material';

import { collection, query, where, doc, getDoc, setDoc, getDocs } from "firebase/firestore"; 




import '../../style.css';

import Header from '../../Header'


export default function Chat_users() {
    
    const { currentUser } = useAuth();
    const uid = currentUser.uid;
    const [chattss, setChattss] = useState([]);
    const [user, setUser] = useState()

    useEffect(() => {
        getChatUsers();
    }, []);

    
 
    async function getChatUsers(){
        const chatters = [];
        const q = query(collection(firestore, "users"),where("uid", "==", uid));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            //console.log(doc.data()); 
            const data = doc.data().Messages;

            setUser( doc.data() )

            data.forEach((i) => {
                //var arr = {id: [i.Reciever, i.recieverName]};
                const arr = 
                    {id: i.Reciever, name: i.recieverName, last: i.recieverLastName}
                
                const isFound = chatters.some(element => {
                    if (element.id == i.Reciever) {
                        console.log("bad emily");
                        return true;
                    }
                    else {
                        return false;
                    }
                })

                if(!isFound) {
                    chatters.push(arr);

                }
            })
            
            console.log(chatters[0].id)
            console.log(chatters[0].name)
        })
        setChattss(chatters);
    }


    
    // function mapping() {
    //     const allMatches = chatters.map((item) => (
    //         <div key ={item.id[0]}> 
    //             {item.id[1]}
                
            
    //         </div>
    //     ))


    // }
    //console.log(chatters[0][0]);

    // const allMatches = chatters.map((item) => (
    //     <li key= {item.id}> {item.name} </li>
    // ));

    // console.log(allMatches);
    console.log(chattss);

    return (
    <>
            {/* <header>
                <svg xmlns="http://www.w3.org/2000/svg" className="heart current h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                </svg>
                <a href="../chat_users/" className="icon"><svg xmlns="http://www.w3.org/2000/svg" className="comment h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                </svg></a>
                <a href="../settings/" className="icon"><svg xmlns="http://www.w3.org/2000/svg" className="gear h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                </svg></a>
                <div>{currentUser?.first}</div>
                <img className="icon logo" src="logo.png"/>
                <h1>Blind Love</h1>
            </header> */}

        <Header chat={true} user={user} />
            
            
        <Box className="" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h4" component="h4" textAlign="center" color="white">
                <h3> Click on a User to Continue Chatting and View Previous Messages</h3>
            </Typography>
            {

                chattss.map((item) => (
                    <Button href={`/chat_page/${item.id}`} key ={item.id} class = "name" id = "name">

                        <Paper fullWidth className=""  elevation={3} sx={{ borderRadius: 2 , m: 1, minWidth: "500px", maxWidth: "800px", bgcolor: 'white'}} >
                            <Box sx={{ p: 3 }}>
                            
                                <Typography variant="h5" component="h5" textAlign="center">
                                    <Badge badgeContent={
                                        user.unread.filter(i => i == item.id).length
                                    } color="primary">
                                        {item.name} {item.last}
                                    </Badge>
                                </Typography>
                            
                            </Box>
                        </Paper>

                    </Button>
                ))
            }
        </Box>

           
    </>
    )  
}

