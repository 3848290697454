import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Badge } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export default function Header({user=null, match=false, showHome=false, chat=false, settings=false, showIcons=true}) {
  
  return (
    <Box 
      sx = {{
        position: '-webkit-sticky',
        position: 'sticky',
        top: 0,
        zIndex: 100,
      }}
    >
      <Paper sx={{ p: 2, pl: 3 }}>
        <Grid 
          container
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid item xs={6}>
            {
              showHome &&
              <Tooltip title="Home">
                <IconButton href="/">
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            }
            {
              showIcons &&
              <>
                <Tooltip title="Match">
                  <IconButton href="/match" sx={{ color: match ? "#A12FA1" : "" }}>
                    <FavoriteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Chat">
                  <IconButton href="/chat_users" sx={{ color: chat ? "#A12FA1" : "" }}>
                    <Badge badgeContent={user?.unread?.length} color="primary">
                      <ChatBubbleIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Settings">
                  <IconButton href="/settings" sx={{ color: settings ? "#A12FA1" : "" }}>
                    <ManageAccountsIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
            
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="h5">
                <Tooltip title="Back to home">
                  <Link href="/" underline="none" color="inherit" >
                    <b>BlindLove</b>
                  </Link>
                </Tooltip>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
