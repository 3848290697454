import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../config'
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
    signOut, 
    sendPasswordResetEmail, 
    updateEmail, 
    updatePassword,
    onAuthStateChanged
} from "firebase/auth";


const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}


export function AuthProvider( { children } ) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLodaing] = useState(true)


    async function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function logout() {
        return signOut(auth)
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email)
    }

    function updateEmailLocal(email) {
        return updateEmail(auth.currentUser, email)
    }

    function updatePasswordLocal(password) {
        return updatePassword(auth.currentUser, password)
    }

    useEffect(() => {

        const unsubscribe =  onAuthStateChanged(auth, user => {
            setCurrentUser(user)
            setLodaing(false)
        })

        return unsubscribe
    }, [])


    const value = {
        currentUser, 
        signup, 
        login,
        logout, 
        resetPassword, 
        updateEmailLocal,
        updatePasswordLocal,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}