import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom";

import { useAuth } from '../../contexts/AuthContext';
import Header from '../../Header'
import { firestore, analytics } from '../../config';
import { doc, setDoc, getDoc, updateDoc, arrayUnion, collection } from "firebase/firestore";
import { logEvent } from 'firebase/analytics';

import { Paper, Typography, Box, IconButton, Tooltip, Badge } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import BlockIcon from '@mui/icons-material/Block';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';

export default function Match() {
    const [match, setMatch] = useState(null)
    const [user, setUser] = useState(null)
    const [preferences, setPreferences] = useState(null)
    const [userMatches, setMatches] = useState(null)
    const [count, setCount] = useState(0)
    const [matchAge, setMatchAge] = useState(18)
    const { currentUser } = useAuth()
    const [loading, setLoading] = useState(true)
    const [showHelp, setShowHelp] = useState(true)
    const [showMessages, setShowMessages] = useState(false)
    let navigate = useNavigate()



    


    useEffect(() => {
        async function dummy(){
            if (currentUser) {
                const userData = await getUserProfile(currentUser.uid, setUser)
                getPreference(userData)
            }
        }
        dummy()
    }, [currentUser])

    useEffect(() => {
        if (match) {
            console.log("match", 1 <= match.Messages.filter(message => message.Reciever === user.uid).length)
            setShowMessages(1 <= match.Messages.filter(message => message.Reciever === user.uid).length)
        }
    }, [match])
                    

    function NewChat() {
        setLoading(true)
        logEvent(analytics, 'new_chat_clicked', {
            user: currentUser.uid,
            match: match.uid,
        });
        console.log("chatting?")
        // add current match to chatters
        // redirect to chat page
        navigate("/chat_page/"+match.uid)
    }
    
    function Skip() {
        console.log("skipping?")
        logEvent(analytics, 'skip_clicked', {
            user: currentUser.uid,
            match: match.uid,
        });
        setLoading(true)
        getNextMatch()
    }

    async function No() {
        console.log("noing?")
        logEvent(analytics, 'reject_clicked', {
            user: currentUser.uid,
            match: match.uid,
        });
        console.log(match)
        setLoading(true)

        await updateDoc(doc(firestore, "users", currentUser.uid), {
            blockedUsers: arrayUnion(match.uid),
        })
        // remove from matches array
        let temp = userMatches
        if (userMatches.length > 1) {
            temp.splice(count, 1)
            setMatches(temp)
        }
        else {
            setMatches([])
            temp = []
        }
        // get next match
        getNextMatch(temp, false)
    }
    async function getUserProfile(uid, set) {
        if (uid) {
            const userRef = doc(firestore, "users", uid)
            const userData = await getDoc(userRef)
            set(userData.data())
            setMatchAge(calculate_age((userData.data()).birthday))
            return userData.data()
                
        }
    }
    function getPreference(userData) {
        let genderPreference = []
        let orientationPreference = []
        if (userData?.orientation === "gay") {
            // do something
            genderPreference.push(userData.gender)
            orientationPreference.push(["gay", "bisexual"])
        } else if (userData?.orientation === "straight"){
            // do something
            orientationPreference.push(["straight", "bisexual"])
            if (userData?.gender === "male") {
                genderPreference.push("female")
            } else if (userData?.gender === "female") {
                genderPreference.push("male")
            } else {
                genderPreference = ["male", "female", "non-binary", "other"]
                orientationPreference = [["bisexual"], ["bisexual"], ["bisexual"], ["bisexual"]]
            }
        } else if (userData?.orientation === "bisexual"){
            // do something
            genderPreference = ["male", "female", "non-binary", "other"]
            if (userData?.gender === "male") {
                orientationPreference.push(["gay", "bisexual"])
            } else if (userData?.gender === "female"){
                orientationPreference.push(["straight", "bisexual"])
            } else {
                orientationPreference.push(["bisexual"])
            }

            if (userData?.gender === "female") {
                orientationPreference.push(["gay", "bisexual"])
            } else if (userData?.gender === "male"){
                orientationPreference.push(["straight", "bisexual"])
            } else {
                orientationPreference.push(["bisexual"])
            }

            if (userData?.gender === "non-binary") {
                orientationPreference.push(["bisexual"])
            } else if (userData?.gender === "female"){
                orientationPreference.push(["bisexual"])
            } else {
                orientationPreference.push(["bisexual", "male", "female", "other"])
            }

            if (userData?.gender === "non-binary") {
                orientationPreference.push(["bisexual"])
            } else if (userData?.gender === "female"){
                orientationPreference.push(["bisexual"])
            } else {
                orientationPreference.push(["bisexual", "male", "female", "other"])
            }
        } else {
            // do something
            genderPreference = ["male", "female", "non-binary", "other"]
            orientationPreference = [["bisexual"], ["bisexual"], ["bisexual"], ["bisexual"]]
        }
        getMatches(genderPreference, orientationPreference, userData)
    }
    async function getMatches(genderPreference, orientationPreference, userData) {
        let matches = []
        for (let i = 0; i < genderPreference.length; i++) {
            for (let j = 0; j < orientationPreference[i].length; j++) {
                const matchRef = doc(firestore, genderPreference[i], orientationPreference[i][j])
                const matchData = await getDoc(matchRef)
                const possibleMatches = matchData.data()
                if (possibleMatches) {
                    matches = matches.concat(possibleMatches["people"])
                }
            }
        }
        let temp = []
        for (let i = 0; i < matches.length; i++) {
            // console.log("i = ", i)
            // console.log(matches[i])
            if (matches[i] === "" || matches[i] === null || matches[i] === undefined|| matches[i] === currentUser.uid) {
                console.log("removing shit")
            } else {
                temp.push(matches[i])
            }
            // console.log(matches)
        }
        matches = temp
        temp = []
        for (let i = 0; i < matches.length; i++) {
            let found = false
            for (let k = 0; k < userData.blockedUsers.length; k++) {
                if (matches[i] === userData.blockedUsers[k]) {
                    found = true
                }
            }
            if (!found) {
                temp.push(matches[i])
            }
        }
        matches = temp.sort(() => Math.random() - 0.5)
        console.log(matches)
        setMatches(matches)
        getNextMatch(matches)
    }
    
    function getNextMatch(matches=userMatches, inc=true) {
        if (matches.length === 0) {
            setMatch("")
            setLoading(false)
            return
        }
        let newCount = count
        if (newCount >= matches.length) {
            newCount = 0
        }
        console.log(newCount)
        console.log(matches)
        if (inc) {
            newCount = count + 1
            if (newCount >= (matches.length)) {
                newCount = 0
            }
            setCount(newCount)
        }
        console.log("calling get user ", matches[newCount])
        getUserProfile(matches[newCount], setMatch)
        setLoading(false)
    }


    function calculate_age(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
            {
                age--;
            }
            return age;
    }

    // function calculate_age(dob1){
    //     var today = Date.now()
    //     var age = parseInt((today - dob1.seconds*1000)/31557600000)
    //     return age;
    // }
    if (!match && loading) {
        return (
            <div>
                loading...
            </div>
        )
    } 

    return (
        <>
            <Header match={true} user={user} />

            {showHelp && (
                
                    <Box 
                    display="flex" 
                    // bgcolor="lightgreen"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        minHeight: "30vh",
                        mt: "20px"
                    }}
                >  
                <Badge color="secondary" badgeContent="x" onClick={() => setShowHelp(false)} sx={{ maxWidth: "700px", width: "100%" }}>
                <Paper className="match-card" elevation={3} sx={{ maxWidth: "700px", width: "100%" }} >
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h6" component="body1">
                            <b>How to use BlindLove</b>
                            <br />
                            Click
                            <IconButton sx={{ color: "#43a047" }} component="span">
                                    <ChatBubbleOutlineIcon />
                                </IconButton> to chat with your potential match (they will move to chat page)
                            <br />
                            Click
                            <IconButton sx={{ color: "#ff6d00" }} component="span" >
                                <ArrowForwardIosIcon />
                            </IconButton> to skip your potential match (they may appear again)
                            <br />
                            Click
                            <IconButton sx={{ color: "#dd2c00" }} component="span">
                                    <ClearIcon />
                                </IconButton> to reject your protential match (you will not see them again)

                        </Typography>
                    </Box>

                </Paper>
                </Badge>
            </Box> 
                
            
            )}


            
            {
                match ?
                // centered mui box
                
                <Box 
                    display="flex" 
                    // bgcolor="lightgreen"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        minHeight: showHelp ? "40vh" : "80vh",
                    }}
                >   
                    <Paper className="match-card" elevation={3} sx={{ maxWidth: "700px", width: "100%" }} >
                        <Box sx={{ p: 3 }}>
                            <Typography variant="h5" component="h3">
                                <b>{match.first} {match.last}</b>, {matchAge}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {match.bio}
                            </Typography>
                        </Box>
                        <Box 
                            display="flex" 
                            // bgcolor="lightgreen"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                pb: 3,
                                pt: 1,
                            }}
                        >   
                            <Tooltip title={"Reject " + match.first}>
                                <IconButton disabled={loading} sx={{ px: 3, color: !loading ? "#dd2c00" : "" }} aria-label="Block user" onClick={No} component="span">
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Message " + match.first}>
                                
                                    <IconButton disabled={loading} sx={{ px: 3, color: !loading ? "#43a047" : "" }} aria-label="Message user" onClick={NewChat} component="span">
                                    <Badge color="primary" variant="dot" invisible={!showMessages}>
                                        <ChatBubbleOutlineIcon />
                                    </Badge>
                                    </IconButton>
                                
                            </Tooltip>
                            <Tooltip title={"Skip " + match.first}>
                                <IconButton disabled={loading || (userMatches.length === 1)} sx={{ px: 3, color: !(loading || (userMatches.length === 1)) ? "#ff6d00" : "" }} aria-label="Skip user" onClick={Skip} component="span" size='xl'>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        
                    </Paper>
                </Box>
                :
                <Box 
                    display="flex" 
                    // bgcolor="lightgreen"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: "100vw",
                        minHeight: showHelp ? "40vh" : "80vh",
                    }}
                >   
                    <Paper className="match-card" elevation={3} sx={{ maxWidth: "700px", width: "100%" }} >
                        <Box sx={{ p: 3 }}>
                            <Typography variant="h5" component="h3">
                                Oh no! No matches found.
                            </Typography>
                            <Typography variant="body2" component="p">
                                You've seen everyone on BlindLove right now. Try back another day.
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
                // <div className="noMatch">
                //     <br/>
                //     <br/>
                //     <br/>
                //     <br/>
                //     <h2>No Matches</h2>
                //     <p>You have no matches at the moment. Try again later.</p>
                // </div>
            }
        </>
    )
  }