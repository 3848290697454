import React, { useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import  { analytics } from '../../config';
import { logEvent } from 'firebase/analytics';

export default function Logout() {
    const { logout } = useAuth()
    const [error, setError] = React.useState(null)
    let navigate = useNavigate()
    async function handleLogout() {
        try {
            await logout()
            logEvent(analytics, 'logout');
            navigate("/")
        } catch (error) {
            console.log(error)
            setError("Error logging out")
        }
    }

    useEffect(() => {
      handleLogout()
    }, [])
    
  return (
    <div>{error || "Logging your out..."}</div>
  )
}
