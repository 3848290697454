import React from 'react'
import Header from '../../Header'

import { Paper, Typography, Box, IconButton, Tooltip } from '@mui/material';


export default function Info() {

  return (
    <>
      <Header showIcons={false} showHome={true} />
      <Box 
          display="flex" 
          // bgcolor="lightgreen"
          alignItems="center"
          justifyContent="center"
          sx={{
              minHeight: "80vh",
          }}
      >   
          <Paper className="match-card" elevation={3} sx={{ maxWidth: "700px", width: "100%" }} >
              <Box sx={{ p: 3 }}>
                  <Typography variant="h5" component="h3">
                      <b>What is BlindLove?</b>
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ textAlign: "center" }}>
                    <br />
                    Blind Love is a web-based dating app based off of the TV show Love is Blind.
                    <br />
                    <br />
                    After you have created a profile, you will see potential matches shown on the matches page.
                    <br />
                    <br />
                    Choose to chat, ignore, or block a potential match. There are no pictures during this process, just names and bios.
                    <br />
                    <br />
                    The chat process will allow you to develop a deeper connection without the superficial aspects of connections based on physical attraction. Once our algorithm decides you have developed a connection, you will see the other person's photo.
                    <br />
                    <br />
                    We can't wait for you to form matches!
                  </Typography>
              </Box>
          </Paper>
      </Box>
        
    </>
  )
}
