import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext';
import firebase from 'firebase/compat/app';

import {firestore, analytics} from '../../config';
import 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore'

import { collection, doc, getDocs, updateDoc, query, where, arrayUnion, arrayRemove } from "firebase/firestore"; 
import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutline';
import Header from '../../Header'

import Chip from '@mui/material/Chip'
import { borders } from '@mui/system';

import { Paper, Typography, Box, IconButton, Tooltip, FormControl, Grid, TextField, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { logEvent } from "firebase/analytics";

import '../../style.css';


export default function Chat_page() {

    const { currentUser } = useAuth();
    const uid = currentUser.uid;
    
    var url = window.location.href;
    var recieverId = url.split('/').pop();
    let recieversName ="";
    let recieversLastName ="";
    let moi ="";
    let picture = "";
    let myPicture = "";
    console.log(recieverId);

    const [chattss, setChattss] = useState([]);
    const [you, setYou] = useState([]);
    const [youLast, setYouLast] = useState([]);
    const [me, setMe] = useState([]);
    const [pic, setPic] = useState([]);
    const [mypic, setMyPic] = useState([]);
    const [user, setUser] = useState();
    const [messaged, setMessaged] = useState(false)

    useEffect(async () => {
        let chats = await getRecieverMessages();
        chats = await getMyMessages(chats);
        await combine(chats);
    }, []);
    const [, updateState] = React.useState();

    //const chats1 = []
   
 
    async function getRecieverMessages(){
        const chats = []

        await updateDoc(doc(firestore, "users", currentUser.uid), {
            unread: arrayRemove(recieverId)
        })

        const q = query(collection(firestore, "users"),where("uid", "==", recieverId));
        const querySnapshot = await getDocs(q);
        
        
        querySnapshot.forEach((doc) => {
            recieversName = doc.data().first;
            recieversLastName = doc.data().last;
            
            console.log(recieversName)
            picture = doc.data().profileHref;
            const data = doc.data().Messages;
            console.log(data);
            data.forEach((i) => {
                
               if(i.Reciever == uid) {
                   console.log("good emily");
                    chats.push(i)
                    setMessaged(true)
               } else {
                   console.log("this", i.Reciever, currentUser.uid);
               }
            })
        })
        console.log("here", chats);
        return chats;

    }

    async function getMyMessages(oldChats) {
        const chats = []
        console.log("second");
        const q = query(collection(firestore, "users"), where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
       
        querySnapshot.forEach((doc) => {
            moi = doc.data().first;
            setUser(doc.data())
            console.log("secondlol");
            //console.log(doc.data().Messages); 
            const data = doc.data().Messages;
            myPicture = doc.data().profileHref;
            data.forEach((i) => {
                if (i.Reciever == recieverId) {
                    console.log(i.When);
                    chats.push(i)
                }
            })
        })
        console.log(chats);
        console.log(recieversName);
        const combinedChat = oldChats.concat(chats);
    

        console.log(combinedChat);
        setYou(recieversName);
        setYouLast(recieversLastName);
        console.log(you);
        setPic(picture);
        setMyPic(myPicture);
        return combinedChat;
    }

    async function combine(chats){
        console.log("emily cant type")
        chats.sort(function(x,y) {
            console.log("emily ")
            console.log(x.When - y.When)
            return x.When - y.When;

        })
        console.log(chats);
        setChattss(chats);
        setMyPic(myPicture);
        setYouLast(recieversLastName);
        setMe(moi);
    }

    
    // getRecieverMessages();
    // getMyMessages();
    // combine();
    let count = 0;
    console.log(count);
    async function sendMessage(e) {
        count++;
        console.log(count);
        e.preventDefault();
        const message_val = document.getElementById('mtext').value;
        if (!message_val) {
            return;
        }
        console.log(message_val)
        const senderId = uid;
        const recieverid = recieverId;
        const recieveName = you;
        const recieveLastName = youLast;
        console.log(recieveLastName);
        console.log(youLast);
        const time = new Date();
        const timestamp = time.getTime();
        var input = document.getElementById('mtext');
        input.value = '';
        var arr = [{Message: message_val, Reciever: recieverid, When: timestamp, recieverName: recieveName, recieverLastName: recieveLastName}]
        logEvent(analytics, "sent_message", {
            "senderId": senderId,
            "recieverId": recieverid,
            "message": message_val,
            "timestamp": timestamp,
            "recieverName": recieveName,
            "recieverLastName": recieveLastName
            });
        await updateDoc(doc(firestore, "users", currentUser.uid), {
            blockedUsers: arrayUnion(recieverid),
        })
        await updateDoc(doc(firestore,"users",currentUser.uid), {
            Messages: arrayUnion(...arr)
        })
        if (messaged) {
            await updateDoc(doc(firestore, "users", recieverId), {
                unread: arrayUnion(currentUser.uid)
            })
        }

        setChattss(oldArray => [...oldArray,{Message: message_val, Reciever: recieverid, When: timestamp, recieverName: recieveName}] );
        // let temp = chattss
        // temp.push({Message: message_val, Reciever: recieverid, When: timestamp, recieverName: recieveName})
        // setChattss(...temp)
        // console.log(chattss)

        

    }

    const handleKeyDown = event => {
        if (event.which === 13) {//<------use enter key to submit form
          sendMessage(event);
        }
      };


    return (
        <>
                <Header chat={true} user={user} />
                
                <div id = "chat_users" class = "chat_users">
                    {/* <div class = "name" id = "you"> */}
                    <Box 
                        sx={{ 
                            p: 0, 
                            color: '#b9f6ca',
                            position: '-webkit-sticky',
                            position: 'sticky',
                            top: "70px",
                            zIndex: 99,
                            backgroundColor: "#CF8CE0"
                        }}
                    >
                        <Typography variant="h3" component="h3" color={"white"}>
                            <b>{you} {youLast}</b>
                        </Typography>
                        
                    </Box>
                    

                    {/* </div> */}
                    {
                        chattss.map((item) => (

                                    item.Reciever === uid ?

                                    <div>
                                        <div key ={item.Reciever} class = "message" id = "you">
                                            <Paper className="message"  elevation={3} sx={{ borderRadius: 4 ,minWidth: "50px", maxWidth: "600px",  bgcolor: '#b9f6ca',}} >
                                                <Box sx={{ p: 1, pr: 2}}>
                                                    <Typography variant="h5" component="h5">
                                                        {item.Message}
                                                    </Typography>
                                                </Box>
                                            </Paper>
                                        </div>
                                        <div class = "message" id = "you">
                                        <Box sx={{fontsize: 1, p: 1, pl: 2}}>
                                            <h6>
                                                {new Date(item.When).toLocaleDateString("en-US")}, {new Date(item.When).toLocaleTimeString("en-US")}
                                            </h6>
                                        </Box>
                                        </div>
                                    </div>

                                :

                                <div>

                                    <div key ={item.Reciever} class = "message" id = "me">
                                        <Paper className="message"  elevation={3} sx={{ borderRadius: 4 , minWidth: "50px",maxWidth: "600px", bgcolor: '#white',}} >
                                            <Box sx={{ p: 1, pl: 1.5}}>
                                                <Typography  variant="h5" component="h5">
                                                    {item.Message}
                                                </Typography>
                                            </Box>
                                        </Paper>
                                    </div>
                                    <div class = "message" id = "me">
                                        <Box sx={{fontsize: 1, p: 1, pl: 2}}>
                                            <h6>
                                                {new Date(item.When).toLocaleDateString("en-US")}, {new Date(item.When).toLocaleTimeString("en-US")}
                                            </h6>
                                        </Box>
                                    </div>
                                </div>
                        ))
                    }

                        

                </div>
                <div class="butt">
                <form onSubmit={sendMessage}>
                    {/* <input type="text" id="mtext" placeholder='Enter your message'/> */}
                    {/* <textarea id="mtext" rows="0" cols="60">
                       
                    </textarea>
                    <button type="submit">💕</button> */}
                    <Paper>
                    <TextField
                        id="mtext"
                        label="Message"
                        variant="outlined"
                        color="secondary"
                        onKeyDown={handleKeyDown}
                        fullWidth
                        multiline
                        rows={2}
                        size="small"
                        inputProps={{
                            'aria-label': 'description',
                        }}
                    />
                    </Paper>
                    
                    <Button color="secondary" type="submit" variant="contained" size="small" startIcon={<SendIcon />}>
                        Send
                    </Button>
                
                </form>
                </div>
            
                <div class="wait">
                {chattss.length >= 25 ? (
                    <div>
                        <Box sx={{ p: 4, pl:1, color: '#b9f6ca'}}>
                        <Typography variant="h3" component="h3" color="white">
                            PHOTO REVEAL!
                        </Typography>
                        <Typography variant="h4" component="h4" color="white">
                            Congrats on matching {you} and {me}
                        </Typography>
                        </Box>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <img alt="user pic" src={pic} width="30%"></img>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4">
                                    +
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <img alt="user pic" src={mypic} width="30%"></img>
                            </Grid>
                        </Grid>

                        {/* // <div class="grid-container">
                        //     <img alt="user pic" src={pic} width="30%"></img>
                        //     <Typography variant="h4" component="h4">
                        //         +
                        //     </Typography>
                        //     <img alt="user pic" src={mypic} width="30%"></img>
                        // </div> */}
                    </div>
                            

                ) : (
                    //  <h5>waiting for match.... keep messaging to </h5>
                     <Typography variant="h6" component="h6">
                        Waiting for match.... keep messaging {you} to reveal your photo. 
                    </Typography>
                )}

                </div>
                
    
               
        </>
    )

}
