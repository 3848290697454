import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom";

import { useAuth } from '../../contexts/AuthContext';
import { firestore, storage, analytics } from '../../config';
import { ref, uploadBytes, getStorage, getDownloadURL } from "firebase/storage";
import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore"; 

import '../../style.css';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
// import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';
import { Paper, Typography, Box, Alert } from '@mui/material';
import { logEvent } from "firebase/analytics";

import Header from '../../Header'

const Input = styled('input')({
    display: 'none',
  });

export default function Create() {
    let navigate = useNavigate();
    const { signup } = useAuth();
    const [error, setError] = React.useState(null);
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [about, setBio] = useState('')
    const [gender, setGender] = useState('')
    const [orientation, setOrientation] = useState('')
    const [file, setFile] = useState()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState()
    const [birthday, setBirthday] = useState()
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()  // prevent page reload
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        setLoading(true)
        const result = pattern.test(email);
        console.log(file)
            if(file === undefined) {
                setError("Add a photo")
                setLoading(false)
                return
            }
        

            if(!fname)
            {
                setError("Add your first name")
                setLoading(false)
                return
            }
            if(!lname) 
            {
                setError("Add your last name")
                setLoading(false)
                return
            }
            
            if(!email)
            {
                setError("Add an email")
                setLoading(false)
                return
            }
            if(!password)
            {
                setError("Add a password")
                setLoading(false)
                return
            }
            
            
            
            if(!birthday)
            {
                setError("Add your birthday")
                setLoading(false)
                return
            }
            
            if(!gender) 
            {
                setError("Add your gender")
                setLoading(false)
                return
            }
            if(!orientation)
            {
                setError("Add your orientation")
                setLoading(false)
                return
            }
            
            if(!about) 
            {
                setError("Add an about field")
                setLoading(false)
                return
            }
            

        if(result==false){
            setError("Incorrect e-mail format")
            setLoading(false)
            return
        }
        if(password.length<6){
            setError("Password must be 6 characters.")
            setLoading(false)
            return
        }
        
        try {
            // create an accounnt with firebsae auth
            const user = await signup(email, password);
            const storage = getStorage();
            const storageRef = ref(storage, 'images/'+user?.user?.uid);
            const uploadTask = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(ref(storage, 'images/'+user?.user?.uid))

            // create a profile document in firestore
            // TODO: get all values from form
            await CreateProfile(
                user?.user?.uid,
                fname,
                lname,
                email,
                downloadURL, // picture
                birthday,
                gender,
                orientation, 
                about,
            )
            setLoading(false)
            navigate('/match');
        } catch (error) {
            console.log(error)
            setLoading(false)
            setError('Account with this e-mail exists. Please Log In.')
        }
    }
    
    async function CreateProfile(
        uid,
        firstName,
        lastName,
        email,
        profileImage,
        birthday, 
        gender, 
        orientation,
        bio,
    ){      
        // gender and orientation to lowercase
        orientation = orientation.toLowerCase()
        if (orientation == "lesbian") {
            // use "gay" for all same sex preferences
            orientation = "gay"
        }
        gender = gender.toLowerCase()
        await setDoc(doc(firestore, "users", uid), {
            first: firstName, 
            last: lastName,
            email: email,
            profileHref: profileImage,
            birthday: birthday,
            gender: gender, 
            orientation: orientation, 
            bio: bio, 
            blockedUsers: [], 
            Messages: [], 
            uid: uid,
        });

        await updateDoc(doc(firestore, gender, orientation), {
            people: arrayUnion(uid)
        });
        logEvent(analytics, "create_account", {
            uid: uid, 
        })
    }

  return (
    <>
        <Header showIcons={false} />
        <div className="background">
            <div className="welcome">
                <h2>Welcome to Blind Love</h2>
            </div>
            <Box 
                display="flex" 
                // bgcolor="lightgreen"
                alignItems="center"
                justifyContent="center"
                sx={{
                    minheight: "80vh",
                }}
            >   
            <Paper className="match-card" elevation={3} sx={{ maxWidth: "800px", width: "100%" }} >
                <Box sx={{ px: 3, pb: 1, pt: 3 }}>
                    <Typography variant="h5" component="h3">
                        <b>Sign up here!</b>
                    </Typography>
                </Box>
                <Box sx={{ p: 2 }}>

                    <form className="" onSubmit={handleSubmit}>
                        <TextField 
                            required 
                            sx={{ my: 1 }} 
                            fullWidth 
                            id="fname" 
                            label="First Name" 
                            variant="outlined"
                            onChange={(e) => setFname(e.target.value)} 
                            error={error === "Add your first name"}
                        />
                        <br/>
                        <TextField 
                        required 
                        sx={{ my: 1 }} 
                        fullWidth 
                        id="lname" 
                        label="Last Name" 
                        variant="outlined" 
                        onChange={(e) => setLname(e.target.value)} 
                        error={error === "Add your last name"}
                        />
                        <br/>
                        <TextField 
                        required 
                        sx={{ my: 1 }} 
                        fullWidth 
                        id="email" 
                        label="Email" 
                        variant="outlined" 
                        onChange={(e) => setEmail(e.target.value)} 
                        error={error === "Incorrect e-mail format" || error === "Account with this e-mail exists. Please Log In." || error === "Add an email"}
                        />
                        <br/>
                        <TextField 
                        required 
                        sx={{ my: 1 }} 
                        fullWidth 
                        id="password" 
                        type="password" 
                        label="Password" 
                        variant="outlined" 
                        onChange={(e) => setPassword(e.target.value)}
                        error={error === "Add a password"}
                        />
                        <br/>
                        <h5>Password must be at least 6 characters</h5>
                        <br/>
                        <TextField 
                        required 
                        sx={{ my: 1 }} 
                        type="date" 
                        fullWidth 
                        id="birthday" 
                        label="Birthday" 
                        variant="outlined" 
                        onChange={(e) => setBirthday(e.target.value)}
                        error={error === "Add your birthday"}
                         />
                        <br/>
                        <label htmlFor="contained-button-file">
                        <Input required accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => {console.log(e.target.files[0]); setFile(e.target.files[0])}} />
                            <Button 
                            sx={{ my: 1  }} 
                            color={"secondary"}
                            variant="contained" 
                            component="span"

                            >
                                <PhotoCamera /> &nbsp; Upload Profile Picture *
                            </Button>
                            &nbsp; &nbsp;{file?.name}
                        </label>
                        <br/>
                        <FormControl 
                        required 
                        sx={{ my: 1  }} 
                        fullWidth
                        error={error === "Add your gender"}
                        >
                                    <InputLabel id="gender">Gender</InputLabel>
                                    <Select
                                        labelId="gender"
                                        id="gender"
                                        label="Gender"
                                        onChange={(e) => setGender(e.target.value)}
                                    >
                                        <MenuItem value={"male"}>Male</MenuItem>
                                        <MenuItem value={"female"}>Female</MenuItem>
                                        <MenuItem value={"non-binary"}>Non-binary</MenuItem>
                                        <MenuItem value={"other"}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                                <br/>
                                <FormControl error={error === "Add your orientation"} required sx={{ my: 1  }} fullWidth>
                                    <InputLabel id="orientation">Sexual Orientation</InputLabel>
                                    <Select
                                        labelId="orientation"
                                        id="orientation"
                                        label="Sexual Orientation"
                                        onChange={(e) => setOrientation(e.target.value)}
                                    >
                                        <MenuItem value={"straight"}>Straight</MenuItem>
                                        <MenuItem value={"gay"}>Gay</MenuItem>
                                        <MenuItem value={"lesbian"}>Lesbian</MenuItem>
                                        <MenuItem value={"bisexual"}>Bisexual</MenuItem>
                                    </Select>
                        </FormControl>
                        <br/>
                        <TextField erorr={error==="Add an about field"} required  multiline minRows = { 3 } maxRows= { 6 } sx={{ my: 1  }} fullWidth id="about" label="About" variant="outlined" placeholder='About you...Biggest Fears? Best date ideas? What keeps you up at night?' onChange={(e) => setBio(e.target.value)} />
                        <br/> <br/>
                        <Button disabled={loading} color="secondary" sx={{ p: 1 }} fullWidth onClick={handleSubmit} variant="contained">Sign Up!</Button>
                    
                        {/* <label htmlFor="fname">First name:</label>
                        <input type="text" name="fname"/><br></br>
                        <label htmlFor="lname">Last name:</label>
                        <input type="text" name="lname"/><br></br>
                        <br></br>
                        <label htmlFor="email">Email:</label>
                        <input type="text" name="email"/><br></br>
                        
                        <label htmlFor="password" type="password" id="password">Password:</label>
                        <input type="text" name="password"/><br></br>
                        <h5>Password must be 6 characters</h5>
                        <label htmlFor="pic"/>Upload your picture here:<br></br>
                        <input type="file" name="pic" id="pic"/><br></br>
                        <label>
                            Birthday:
                            <input type="date" name="bday"/><br></br>
                        </label>
                        <label htmlFor="Gender">Gender:</label>
                        <select name="Gender" id="Gender">
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="non-binary">Non-binary</option>
                            <option value="other">Other</option>
                        </select>
                        <label htmlFor="SexualOrientation">Sexual Orientation:</label>
                        <select name="SexualOrientation" id="SexualOrientation">
                            <option value="straight">Straight</option>
                            <option value="gay">Gay</option>
                            <option value="lesbian">Lesbian</option>
                            <option value="bisexual">Bisexual</option>
                        </select>
                        <br></br>
                        <div className="about">
                            <textarea rows="6" cols="60" name="about" placeholder='About you...Biggest Fears? Best date ideas? What keeps you up at night?'/><br/>
                        </div> */}
                        {/* TODO: make this  red or more obvious if there is an error */}
                        {error ? <Alert severity="error">{error}</Alert> : <br/>}
                        {/* <a href="/match"><input type="submit" value="Sign Up!"/></a> */}
                        <p/>Have an account? <a href="/login">Log In</a>
                    </form>
                </Box>
            </Paper>
            </Box>
            <br /><br /><br /><br /><br /><br /><br />
        </div>

    </>
  )
}
