import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom";

import { useAuth } from '../../contexts/AuthContext';
import { firestore, storage, analytics } from '../../config';
import { ref, uploadBytes, getStorage, getDownloadURL, storageRef } from "firebase/storage";
import { doc, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove, collection, deleteDoc } from "firebase/firestore";
import { deleteUser } from "firebase/auth";

import Header from '../../Header'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
// import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { logEvent } from 'firebase/analytics';


const Input = styled('input')({
    display: 'none',
  });

export default function Settings() {
    const { currentUser } = useAuth()
    const [user, setUser] = useState(null)

    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [bio, setBio] = useState('')
    const [gender, setGender] = useState('')
    const [orientation, setOrientation] = useState('')
    const [file, setFile] = useState()
    const [acceptPassword, setAcceptPassword] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (currentUser) {
            getUserProfile()
        }
    }, [currentUser])

    async function getUserProfile() {
        if (currentUser.uid) {
            const userRef = doc(firestore, "users", currentUser.uid)
            const userData = await getDoc(userRef)
            setUser(userData.data())
            setGender(userData.data().gender)
            setOrientation(userData.data().orientation)
        }

    }


    if (!user) {
        return <div>Loading...</div>
    }

    
    async function handleDeleteUser(e) {
    //     console.log("here")
    //     currentUser.delete().then(function() {
        e.preventDefault()  // prevent page reload
        setLoading(true)
        logEvent(analytics, 'update_account', {
            user: currentUser.uid,
        });
        await updateDoc(doc(firestore, user.gender, user.orientation), {
            people: arrayRemove(currentUser.uid)
        })
        await deleteDoc(doc(firestore, "users", currentUser.uid))        
        deleteUser(currentUser)
    }
    async function handleSubmit(e) {
        setLoading(true)
        let firstName = fname || user.first
        let lastName = lname || user.last
        let about = bio || user.bio
        let fileHref = user.profileHref
        console.log(fileHref);
        if (file) {
            const storage = getStorage();
            const storageRef = ref(storage, `images/${currentUser.uid}`);
            //const storageRef = storage.ref()
            //const fileRef = storageRef.child(`images/${currentUser.uid}`)
            const uploadTask = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(ref(storage, `images/${currentUser.uid}`))
            //const uploadTask = storageRef.put(file)
            //const downloadURL = await uploadTask.then(snapshot => snapshot.ref.getDownloadURL())
            fileHref = downloadURL
        }
        // const file = e.target[4].files[0]

        let newgender = gender || user.gender
        let neworientation = orientation || user.orientation

        neworientation = neworientation.toLowerCase()
        if (neworientation == "lesbian") {
            // use "gay" for all same sex preferences
            neworientation = "gay"
        }
        newgender = gender.toLowerCase()

        await updateDoc(doc(firestore, "users", currentUser.uid), {
            first: firstName, 
            last: lastName,
            profileHref: fileHref,
            gender: newgender, 
            orientation: neworientation, 
            bio: about, 
        });
        // reload the page
        window.location.reload(false);
    }

    return(
        <>
        <Header settings={true} />
            <div className="settings"/>
                <br/>
                <br/>
                
                
                <Box sx={{ maxWidth: '100vw' }}>
                    <Paper sx={{pt:1, pr:2}}>
                    <Typography variant="h4" sx={{ m: 2, alignSelf: "left", textAlign: "left" }} component="h1" gutterBottom color="black">
                            <b>Settings</b>
                    </Typography>
                        <form onSubmit={handleSubmit}>
                            
                            <TextField sx={{ m: 1 }} fullWidth id="fname" label="First Name" variant="outlined" defaultValue={user.first} onChange={(e) => setFname(e.target.value)} />
                            <TextField sx={{ m: 1 }} fullWidth id="lname" label="Last Name" variant="outlined" defaultValue={user.last} onChange={(e) => setLname(e.target.value)} />
                            <TextField multiline minRows = { 3 } sx={{ m: 1 }} fullWidth id="about" label="About" variant="outlined" defaultValue={user.bio} onChange={(e) => setBio(e.target.value)} />
                            <FormControl sx={{ m: 1 }} fullWidth>
                                <InputLabel id="gender">Gender</InputLabel>
                                <Select
                                    labelId="gender"
                                    id="gender"
                                    value={gender}
                                    label="Gender"
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <MenuItem value={"male"}>Male</MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                    <MenuItem value={"non-binary"}>Non-binary</MenuItem>
                                    <MenuItem value={"other"}>Other</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1 }} fullWidth>
                                <InputLabel id="orientation">Sexual Orientation</InputLabel>
                                <Select
                                    labelId="orientation"
                                    id="orientation"
                                    value={orientation}
                                    defaultValue={user.orientation}
                                    label="Sexual Orientation"
                                    onChange={(e) => setOrientation(e.target.value)}
                                >
                                    <MenuItem value={"straight"}>Straight</MenuItem>
                                    <MenuItem value={"gay"}>Gay</MenuItem>
                                    <MenuItem value={"lesbian"}>Lesbian</MenuItem>
                                    <MenuItem value={"bisexual"}>Bisexual</MenuItem>
                                </Select>
                            </FormControl>

                            { file?.name ? "" : <img className="icon" src={user.profileHref} width="30%" />}
                            
                            <br/><br/>
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => {console.log(e.target.files[0]); setFile(e.target.files[0])}} />
                                <Button sx={{ m: 1, mt: 0 }} variant="contained" component="span">
                                    <PhotoCamera /> Upload New Profile Picture
                                </Button>
                                &nbsp; &nbsp;{file?.name}
                            </label>
                      
                            <Button disable={loading} sx={{ m: 1 }} fullWidth onClick={handleSubmit} variant="contained" color="secondary">Save Changes</Button>
                        
                            <a href="/logout">
                                <Button disable={loading} sx={{ m: 1 }} fullWidth variant="contained" color="primary">Log Out</Button>
                            </a>
          
                            <Button disable={loading} sx={{ m: 1 }} fullWidth onClick={handleDeleteUser} variant="">Delete Account</Button>
                        </form>
                    </Paper>
                    
                </Box>
            <div/>
        </>
    )
    }